import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Brokenheart: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M27.011 6.59c-2.835-2.785-7.45-2.785-10.29 0l-.715.705-.715-.705c-2.84-2.785-7.46-2.785-10.295 0a7.06 7.06 0 0 0 0 10.1l11.005 10.805L27.006 16.69a7.027 7.027 0 0 0 2.115-5.05c0-1.92-.75-3.715-2.115-5.05h.005Zm-1.425 8.7-9.58 9.405-9.58-9.405c-2.015-1.98-2.015-5.32 0-7.3 1.01-.99 2.36-1.485 3.715-1.485s2.71.495 3.715 1.485l.865.85 1.25 1.265-1.98 3.025 1.98 2.025-1.615 4.585 3.995-4.585-1.98-2.025 2.095-3.225-1.12-1.13.795-.78c2.015-1.975 5.42-1.975 7.435 0 2.015 1.98 2.015 5.32 0 7.3l.01-.005Z"
    />
  </Icon>
);
