import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Protection: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.75 3.5h14.5c0 1.874 1.913 3.75 3.75 3.75v9.492C27 23.562 16 28.5 16 28.5S5 23.562 5 16.742V7.25c1.952 0 3.75-1.838 3.75-3.75ZM25 8.831A6.393 6.393 0 0 1 21.669 5.5H10.362A6.072 6.072 0 0 1 7 8.862v7.88c0 4.114 5.889 7.957 9 9.539 3.11-1.581 9-5.424 9-9.539V8.831Zm-11.097 11.01 8.826-8.826L21.214 9.5l-7.31 7.31-2.889-2.888L9.5 15.437l4.403 4.404Z"
      clipRule="evenodd"
    />
  </Icon>
);
