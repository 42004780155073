import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const OverseasTravel: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 3C8.82 3 3 8.82 3 16s5.82 13 13 13 13-5.82 13-13S23.18 3 16 3Zm0 2c6.065 0 11 4.935 11 11s-4.935 11-11 11S5 22.065 5 16 9.935 5 16 5Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.936 24.304c-.105 0-.212-.01-.319-.026-.84-.138-1.876-.84-2.03-3.4-.15-2.478-1.146-2.912-2.297-3.413-.98-.427-2.321-1.011-2.28-2.958.02-.866.299-1.56.83-2.061 1.03-.973 2.675-.913 4.268-.855 2.444.086 3.29-.052 3.463-1.055.06-.343.03-.584-.087-.713-.478-.532-2.357-.41-3.368-.342-1.232.08-1.977.13-2.468-.391a1.152 1.152 0 0 1-.306-.937c.114-.903.921-1.514 1.857-2.221.659-.497 1.403-1.061 1.716-1.62a1 1 0 0 1 1.745.978c-.497.887-1.367 1.565-2.156 2.162 1.56-.087 3.455-.092 4.467 1.033.377.419.782 1.173.57 2.39-.5 2.896-3.392 2.789-5.505 2.714-1.066-.037-2.402-.086-2.825.312-.046.044-.19.18-.2.649-.011.55.089.65 1.077 1.08 1.303.569 3.27 1.426 3.495 5.127.08 1.298.397 1.56.4 1.563.174-.023.994-.658 1.587-2.12.058-.145.128-.305.203-.48.418-.961.988-2.278.988-3.928 0-1.025.93-1.981 2.369-2.435 1.864-.588 4.512-.292 6.335 2.313a1 1 0 1 1-1.637 1.147c-1.163-1.661-2.64-1.77-3.418-1.693-.988.103-1.587.576-1.662.745.012 1.988-.68 3.588-1.14 4.647-.067.159-.131.305-.186.438-.698 1.718-2.02 3.35-3.486 3.35ZM7.027 24.211a1 1 0 0 1-.528-1.85c.41-.254.666-.562.76-.915.33-1.217-1.25-2.839-3.52-3.614a1 1 0 1 1 .645-1.892c3.414 1.166 5.436 3.701 4.806 6.028-.227.846-.794 1.57-1.637 2.093-.164.101-.346.15-.526.15Z"
      clipRule="evenodd"
    />
  </Icon>
);
