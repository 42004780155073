import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const TickedEnvelope: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 10.5c0-1.655 1.345-3 3-3h15.5c1.655 0 3 1.345 3 3v6.11c-.686-.127-1.317-.13-2-.075v-5.922l-6.12 5.813a3.799 3.799 0 0 1-2.621 1.047 3.797 3.797 0 0 1-2.622-1.047L7 10.596V21c0 .55.45 1 1 1h10.215a7.194 7.194 0 0 0-.21 1.998V24H8c-1.655 0-3-1.345-3-3V10.5Zm17.768-1H8.75l5.765 5.476c.697.662 1.79.663 2.489 0L22.768 9.5Zm-3.101 14.167a5.667 5.667 0 1 1 11.333 0 5.667 5.667 0 0 1-11.333 0Zm8.261-2.586a.54.54 0 0 0-.291-.081.517.517 0 0 0-.444.24l-2.578 3.97-1.353-1.538c-.272-.274-.648-.134-.747-.047a.522.522 0 0 0-.046.746l1.813 2.066a.493.493 0 0 0 .386.168c.169-.011.366-.087.46-.227l2.952-4.562a.528.528 0 0 0-.152-.735Z"
    />
  </Icon>
);
