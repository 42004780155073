/**
 * Formats a number as 1st, 2nd 3rd etc.
 */

export const getOrdinalFormat = (number: number): string => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const tens = Math.floor((number / 10) % 10);
  const units = number % 10;

  if (tens === 1) {
    return `${number}th`;
  }
  const suffix = suffixes[units] || suffixes[0];
  return `${number}${suffix}`;
};
