import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const House: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.004 3.86 3 13.801v14.318h2v-13.33l11.004-8.412L27 14.789v13.33h2V13.801L16.004 3.86Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 18.129v10h2v-8h4v8h2v-10h-8Z"
      clipRule="evenodd"
    />
  </Icon>
);
