import { isValid, isSameMonth, isAfter, endOfMonth } from 'date-fns';

export function isRealDate(date: Date) {
  if (!isValid(date)) {
    return false; // If not a valid date in terms of structure
  }

  // Check if the day is within the valid range for the month
  if (!isSameMonth(date, endOfMonth(date))) {
    return false;
  }

  return true;
}
