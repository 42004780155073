import { isProd } from '@agria/utils';

const siteLinksDev = {
  brochureware: 'https://staging-agria-brochure.netlify.app',
  makeAPayment: 'https://agria-make-a-payment.netlify.app',
  onlineVetCLaims: 'https://vetclaim.agriapet.co.uk',
  onlineCustomerClaims: 'https://claim.agriapet.co.uk',
  quoteAndBuy: 'https://staging-agria-qab.netlify.app',
  quoteAndBuyContinueCover:
    'https://staging-agria-qab.netlify.app/continue-cover',
  quoteAndBuyRetrieve: 'https://staging-agria-qab.netlify.app/retrieve',
  freeCover: 'https://staging-agria-4wf.netlify.app',
  rehomingCover: 'https://staging-agria-rehoming.netlify.app',
  vetActivatedCover: 'https://staging-agria-vet-activated.netlify.app',
  vetOwnerActivated: 'https://staging-agria-vet-owner-activated.netlify.app',
  breederClub: 'https://staging-agria-breeder-club.netlify.app',
  joinBreederClub: 'https://staging-agria-join-breeder-club.netlify.app',
  litterDataCapture: 'https://agria-litter-data-capture.netlify.app',
  myAccount: 'https://staging-agria-brochure.netlify.app/my-account',
  myRewards: 'http://www.agriarewards.co.uk',
  vetEnvoy: 'https://www.vetenvoy.com/',
  stock: 'https://stock.agriapet.co.uk/login.html',
} as const;

const siteLinksProduction = {
  ...siteLinksDev,
  brochureware: 'https://www.agriapet.co.uk',
  makeAPayment: 'https://makeapayment.agriapet.co.uk',
  onlineVetCLaims: 'https://vetclaim.agriapet.co.uk',
  onlineCustomerClaims: 'https://claim.agriapet.co.uk',
  myAccount: 'https://www.agriapet.co.uk/my-account',
  quoteAndBuyContinueCover: 'https://quote.agriapet.co.uk/continue-cover',
  quoteAndBuyRetrieve: 'https://quote.agriapet.co.uk/retrieve',
  quoteAndBuy: 'https://quote.agriapet.co.uk',
  freeCover: 'https://owners.agriapet.co.uk',
  rehomingCover: 'https://rehoming.agriapet.co.uk',
  vetActivatedCover: 'https://vets.agriapet.co.uk',
  breederClub: 'https://breeders.agriapet.co.uk',
  vetOwnerActivated: 'https://vet-owners.agriapet.co.uk',
  litterDataCapture: 'https://litter-data-capture.agriapet.co.uk',
  joinBreederClub: 'https://join.agriapet.co.uk',
  myRewards: 'http://www.agriarewards.co.uk/',
};
export const siteLinks = isProd ? siteLinksProduction : siteLinksDev;
