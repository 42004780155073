import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Heart: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m25.498 15.29-9.493 9.492-9.493-9.492c-1.996-1.997-1.996-5.37 0-7.368 1-.998 2.341-1.498 3.684-1.498 1.342 0 2.685.5 3.684 1.498l2.125 2.125 2.125-2.125c2-1.996 5.371-1.995 7.368 0 1.997 1.997 1.997 5.371 0 7.368Zm1.414-8.782c-2.81-2.81-7.385-2.812-10.196 0l-.71.711-.712-.71c-2.812-2.813-7.387-2.81-10.196 0-2.81 2.81-2.81 7.384 0 10.195L16.005 27.61l10.907-10.906a7.154 7.154 0 0 0 2.094-5.099 7.151 7.151 0 0 0-2.094-5.097Z"
      clipRule="evenodd"
    />
  </Icon>
);
