import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Search: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m26.32 27.735-5.07-5.07 1.414-1.415 5.071 5.07-1.414 1.415ZM6.234 13.75c0 4.145 3.371 7.517 7.516 7.517 4.144 0 7.516-3.372 7.516-7.517 0-4.145-3.372-7.516-7.516-7.516-4.145 0-7.516 3.371-7.516 7.516Zm-1.984 0c0-5.238 4.262-9.5 9.5-9.5s9.5 4.262 9.5 9.5c0 5.239-4.262 9.5-9.5 9.5s-9.5-4.261-9.5-9.5Z"
      clipRule="evenodd"
    />
  </Icon>
);
