import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Stethoscope: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M28.05 16.115a3.459 3.459 0 0 0-3.455-3.455 3.459 3.459 0 0 0-3.455 3.455 3.459 3.459 0 0 0 2.43 3.3v1.355c0 3.16-2.57 5.73-5.73 5.73-3.16 0-5.73-2.57-5.73-5.73v-.21c3.695-.505 6.555-3.67 6.555-7.5V5.48h-3.02a1.998 1.998 0 0 0-1.715-.98c-1.105 0-2.005.9-2.005 2.005s.9 2.005 2.005 2.005c.73 0 1.365-.395 1.715-.98h.97v5.53c0 3.05-2.48 5.53-5.53 5.53-3.05 0-5.53-2.48-5.53-5.53V7.53h.9c.35.585.985.98 1.715.98 1.105 0 2.005-.9 2.005-2.005A2.012 2.012 0 0 0 8.165 4.5c-.73 0-1.365.395-1.715.98H3.5v7.58c0 3.83 2.86 7 6.555 7.5v.21c0 4.29 3.49 7.78 7.78 7.78s7.78-3.49 7.78-7.78v-1.355a3.464 3.464 0 0 0 2.43-3.3h.005Zm-3.455 1.405a1.406 1.406 0 0 1 0-2.81 1.406 1.406 0 0 1 0 2.81Z"
    />
  </Icon>
);
