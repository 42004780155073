import React, { FC, useId } from 'react';

export interface LogoMasterCardProps {
  title?: string;
}

export const LogoMasterCard: FC<LogoMasterCardProps> = (props) => {
  const id = useId();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 46 32" aria-describedby={id}>
      <title id={id}>{props?.title || 'Mastercard logo'}</title>
      <path
        fill="#3F51B5"
        d="M44.7598 27.4583c0 2.3011-1.8657 4.1667-4.1667 4.1667H5.17643c-2.30104 0-4.16666-1.8656-4.16666-4.1667V4.54167C1.00977 2.24062 2.87539.375 5.17643.375H40.5931c2.301 0 4.1667 1.86562 4.1667 4.16667V27.4583Z"
      />
      <path
        fill="#FFC107"
        d="M29.1349 5.58337c-2.7626 0-5.4122 1.09747-7.3657 3.05098C19.8157 10.5878 18.7183 13.2374 18.7183 16c0 2.7627 1.0974 5.4122 3.0509 7.3657s4.6031 3.051 7.3657 3.051c2.7627 0 5.4122-1.0975 7.3657-3.051 1.9535-1.9535 3.051-4.603 3.051-7.3657 0-2.7626-1.0975-5.4122-3.051-7.36565-1.9535-1.95351-4.603-3.05098-7.3657-3.05098Z"
      />
      <path
        fill="#FF3D00"
        d="M20.8159 22.25c-.4834-.6427-.899-1.3375-1.225-2.0833h5.5468c.2896-.6625.5167-1.3583.6636-2.0833h-6.8729c-.1375-.6729-.2105-1.3688-.2105-2.0834h7.2917c0-.7145-.0729-1.4104-.2094-2.0833h-6.8729c.1479-.725.374-1.4208.6636-2.0833h5.5468c-.326-.7459-.7406-1.4407-1.225-2.08336h-3.0968c.4552-.60417.9687-1.16875 1.5427-1.66146-1.8209-1.55833-4.1813-2.50521-6.7657-2.50521C9.83981 5.58337 5.17627 10.2469 5.17627 16c0 5.7532 4.66354 10.4167 10.41663 10.4167 3.4052 0 6.4188-1.6406 8.3188-4.1667h-3.0958Z"
      />
    </svg>
  )
};
