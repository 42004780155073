import React, { FC, useId } from 'react';

export interface LogoVisaProps {
  title?: string;
}

export const LogoVisa: FC<LogoVisaProps> = (props) => {
  const id = useId();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 46 32" aria-describedby={id}>
      <title id={id}>{props?.title || 'Visa logo'}</title>
      <path
        fill="#2100C4"
        d="M44.7598 27.4583c0 2.3011-1.8657 4.1667-4.1667 4.1667H5.17643c-2.30104 0-4.16666-1.8656-4.16666-4.1667V4.54167C1.00977 2.24062 2.87539.375 5.17643.375H40.5931c2.301 0 4.1667 1.86562 4.1667 4.16667V27.4583Z"
      />
      <path
        fill="#fff"
        d="M13.7033 10.7917 10.9679 18.95s-.6948-3.451-.7636-3.8844c-1.55726-3.5531-3.85518-3.3552-3.85518-3.3552L9.05745 22.25v-.0021h3.29275l4.5531-11.4562h-3.2ZM16.3106 22.25h2.9906l1.8083-11.4583h-3.0281L16.3106 22.25Zm21.1656-11.4583h-3.1469L29.4231 22.25h2.9708l.6125-1.6364h3.7458l.3188 1.6364h2.7219l-2.3167-11.4583Zm-3.6406 7.6333 1.6281-4.3302.8521 4.3302h-2.4802Zm-8.4834-4.2937c0-.6313.5188-1.1011 2.0063-1.1011.9667 0 2.074.7021 2.074.7021l.4854-2.4052s-1.4146-.5365-2.8032-.5365c-3.1447 0-4.7666 1.5042-4.7666 3.4084 0 3.4437 4.1448 2.9719 4.1448 4.7406 0 .3031-.2407 1.0042-1.9667 1.0042-1.7312 0-2.874-.6344-2.874-.6344l-.5156 2.3083s1.1073.6313 3.2469.6313c2.1448 0 5.1198-1.6042 5.1198-3.9084 0-2.7718-4.1511-2.9718-4.1511-4.2093Z"
      />
      <path
        fill="#F5BC00"
        d="m10.6057 16.9844-1.00622-4.9458s-.45521-1.0719-1.63854-1.0719h-4.625s5.89687 1.7416 7.26976 6.0177Z"
      />
    </svg>
  )
};
