import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Coupon: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m14.894 25.624-8.5-8.5L17.55 5.96h4.93l3.5 3.552v4.962l-11.086 11.15Zm12.98-16.558a.996.996 0 0 0-.278-.659L23.73 4.27c-.184-.099-.439-.21-.705-.31H17.16a.938.938 0 0 0-.724.293L4.273 16.416a1 1 0 0 0 0 1.415l9.914 9.914a.996.996 0 0 0 1.414 0l12.08-12.163c.278-.195.384-.463.3-.739l-.108-5.777Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.016 13.059c-.367 0-.648-.128-.937-.461a.5.5 0 0 1-.1-.157l-.055-.13c-.032-.065-.032-.21-.032-.377a1.14 1.14 0 0 1 1.124-1.123 1.14 1.14 0 0 1 1.124 1.123 1.14 1.14 0 0 1-1.124 1.125Zm0-4.249a3.127 3.127 0 0 0-3.124 3.124c0 .317 0 .745.212 1.206.116.314.315.627.51.816a3.076 3.076 0 0 0 2.402 1.103 3.127 3.127 0 0 0 3.124-3.124 3.127 3.127 0 0 0-3.124-3.125Z"
      clipRule="evenodd"
    />
  </Icon>
);
