import { colors } from '../styles/tokens/colors';

type ColorMapType = {
  [animalName: string]: keyof typeof colors;
};

export const getHighlightColor = (
  animalName: string,
  tone?: '100' | '80' | '50' | '20',
  defaultTone?: '100' | '50' | '20'
) => {
  const colorMap: ColorMapType = {
    none: `accentOne${tone || '100'}`,
    dog: `accentOne${tone || '100'}`,
    cat: `accentTwo${tone || '100'}`,
    rabbit: `accentThree${tone || '100'}`,
    horse: `accentFour${tone || '100'}`,
    equine: `accentFour${tone || '100'}`,
    clear: `utilityWhite`,
  };

  // Convert the animal name to lowercase to make the function case-insensitive.
  const animalKey = animalName?.toLowerCase();

  // If the animal is in the map, return its color. Otherwise, return a default color.
  return colorMap[animalKey] || `brandOne${defaultTone || '100'}`;
};
